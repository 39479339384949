import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Departments = () => {
  const data = useStaticQuery(graphql`
    query DepartmentsQuery {
      allStrapiDepartment {
        nodes {
          id
          description
          arabicDescription
          picture {
            publicURL
          }
        }
      }
    }
  `).allStrapiDepartment.nodes;
  return (
    <Layout>
      <SEO title="Departments" />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {data.values &&
            data.map(department => (
              <div
                className="value-element"
                key={department.id}
                style={{ flex: "0 50%", boxSizing: "border-box" }}
              >
                <div
                  className="department-card"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "20px",
                    margin: "50px auto",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    WebkitBoxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    MozBoxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div
                    style={{
                      background: `url(${department.picture.publicURL})`,
                      backgroundSize: "cover",
                      borderRadius: "100px",
                      width: "200px",
                      height: "200px",
                      margin: "0 auto",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      WebkitBoxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      MozBoxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  ></div>
                  <div style={{ padding: "10px", textAlign: "center" }}>
                    {department.description}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default Departments
